







import { SfBreadcrumbs } from '@storefront-ui/vue';
import { computed, defineComponent, ref, useContext } from '@nuxtjs/composition-api';
import { useMyAccountNavigation } from '~/bbrTheme/modules/customer/stores/myAccountNavigation';
import { storeToRefs } from 'pinia';
import { BreadcrumbInterface } from '@amplience/helpers/breadcrumbsResolver';

export default defineComponent({
  name: 'MyAccountBreadcrumbs',
  components: {
    SfBreadcrumbs,
  },
  setup() {
    const store = useMyAccountNavigation();
    const { localePath, i18n } = useContext();
    const { breadcrumbs: currentPage } = storeToRefs(store);
    const defaultBreadcrumbs = ref<BreadcrumbInterface[]>([
      {
        text: i18n.t('Home') as string,
        link: localePath({ name: 'home' }),
      },
      {
        text: i18n.t('My account') as string,
        link: localePath({ name: 'customer-my-account' }),
      },
    ]);

    return {
      breadcrumbs: computed(() => {
        return !currentPage.value.length ?
          defaultBreadcrumbs.value :
          [...defaultBreadcrumbs.value, ...currentPage.value];
      }),
    }
  }
})
