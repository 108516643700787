import type { RawLocation } from 'vue-router';

import { useRouter, useContext } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import { useCart } from '~/modules/checkout/composables/useCart';

type LinkGroup = { title: string, items: LinkGroupItem[] };
type LinkGroupItem = { id: string, label: string, link?: RawLocation };

export const useSidebarLinkGroups = () => {
  const { localeRoute } = useContext();
  const { logout } = useUser();
  const { clear } = useCart();
  const router = useRouter();

  const sidebarLinkGroups : LinkGroup[] = [
    {
      title: 'My Account',
      items: [
        {
          id: 'my-account',
          label: 'Account',
          link: { name: 'customer-my-account' },
        },
        {
          id: 'my-profile',
          label: 'Profile',
          link: { name: 'customer-my-profile' },
        },
        {
          id: 'my-address-book',
          label: 'Address book',
          link: { name: 'customer-my-address-book' },
        },
        {
          id: 'manage-payments',
          label: 'Manage payments',
          link: { name: 'customer-manage-payments' },
        },
        {
          id: 'order-history',
          label: 'Orders, payments & statements',
          link: { name: 'customer-order-history' },
        },
        {
          id: 'my-wishlist',
          label: 'Wishlist',
          link: { name: 'customer-my-wishlist' },
        },
        {
          id: 'my-interests',
          label: 'Interests',
          link: { name: 'customer-my-interests' },
        },
        {
          id: 'my-communication-preferences',
          label: 'Communication preferences',
          link: { name: 'customer-my-communication-preferences' },
        },
        {
          id: 'my-store-credit',
          label: 'Store credit',
          link: { name: 'customer-my-store-credit' },
        }
      ],
    },
  ];

  const logoutUser = async () => {
    await logout({});
    await clear({});
    await router.push(localeRoute({ name: 'home' }));
  };

  return { sidebarLinkGroups, logoutUser };
};
