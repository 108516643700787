



















































import {
  SfMenuItem, SfList, SfButton,
} from '@storefront-ui/vue';
import AccountNav from '~/modules/customer/components/organisms/AccountNav.vue'
import { useAuth } from '~/modules/azure-b2c-auth/composables/useAuth';
import {
  defineComponent,
  useRoute,
  useFetch,
  useContext,
} from '@nuxtjs/composition-api';
import { useSidebarLinkGroups } from './useSidebarLinkGroups';
import { useUser } from '../../composables/useUser';
import { useCellar } from '@cellar-services/composables/useCellar';
import SubNav from '~/bbrTheme/components/organisms/SubNav.vue';
import { navigationLinks } from '~/bbrTheme/modules/customer/stores/myAccountNavigation';
import Breadcrumbs from '~/bbrTheme/modules/customer/components/molecules/Breadcrumbs.vue'
import { isDualRunning } from '~/bbrTheme/helpers/dualRunning';

export default defineComponent({
  name: 'MyAccount',
  components: {
    AccountNav,
    SfList,
    SfMenuItem,
    SubNav,
    SfButton,
    Breadcrumbs,
  },
  middleware: 'is-authenticated',
  setup() {
    const route = useRoute();
    const { app } = useContext();
    const { user, load: loadUser } = useUser();
    const { refreshCellar } = useCellar();
    const page = route.value.path.substring(route.value.path.lastIndexOf('/') + 1);

    useFetch(async () => {
      if (user.value === null) {
        await loadUser();
      }

      // load cellar asynchronously
      if (!isDualRunning(app.$cookies)) {
        refreshCellar();
      }
    });

    const { sidebarLinkGroups } = useSidebarLinkGroups();
    const { logout } = useAuth();

    /**
     * #tab-id: handler-name
     */
    const handlers = {
      'log-out': logout,
    };

    const getHandler = (id: string) => handlers[id] ?? {};

    const handleLogout = async () => await logout();

    return {
      sidebarLinkGroups,
      logout,
      getHandler,
      page,
      navigationLinks,
      handleLogout,
    };
  },
});
